import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';

const HomePage = lazy(() => import('./pages/HomePage/HomePage'));

const Router = (): JSX.Element => {
  return (
    <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
      <Routes>
        <Route path="/migration" element={<HomePage />} />
      </Routes>
    </Suspense>
  );
};
export default Router;
