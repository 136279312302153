import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import { EnvConfig, EnvProvider } from './components';
import { IntlProvider } from './i18n';
import Router from './Router';

export default function App(props: EnvConfig) {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-migration-mfe',
    seed: 'bees-hub-migration-mfe',
  });

  return (
    <EnvProvider env={props}>
      <StylesProvider generateClassName={generateClassName}>
        <IntlProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </IntlProvider>
      </StylesProvider>
    </EnvProvider>
  );
}
